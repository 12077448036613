<template>
  <div>
    <div v-if="!this.$route.query.code">
      <a v-if="gw_auth_link"
        class="btn btn-outline-primary"
        :href="gw_auth_link"
        >
        <img
          height="36"
          src="@/assets/images/logos/Google Workspace.webp"
        >
        Login with Google Workspace
      </a>
      <span v-else>loading...</span>
    </div>
    <span v-else>
      Onboarding finished
    </span>
    <div class="mt-1">
      <a target="_blank" href="https://saasment.com/policy">Privacy policy & terms</a>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      sso: null,
      gw_auth_link: '',
    }
  },
  mounted() {
    this.sso = this.$route.query.code
    if (this.sso) {
      useJwt.addLogin({
        type: 'sso',
        user: 'root',
        app_name: 'Google Workspace',
        details: {
          code: this.$route.query,
          flow: JSON.parse(localStorage.getItem('gwFlow')),
        },
      }).then(() => {
        this.$router.push('Integrations')
      })
    } else {
      useJwt.getGWLink({}).then(response => {
        localStorage.setItem('gwFlow', JSON.stringify(response.data))
        this.gw_auth_link = response.data
      })
    }
  },
}
</script>
